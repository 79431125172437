import React from 'react'
import clsx from 'clsx'
import * as styles from '../styles/components/HeaderWrapper.module.scss'

import banner1 from '../images/banner-main.png'
import iconMain from '../images/header-icon.png'
import { SectionTitle } from './SectionTitle'

interface HeaderWrapperProps {
  isCommonWrapper?: boolean
  pageTitle?: string
}

export const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
  pageTitle,
  isCommonWrapper,
}) => {
  return (
    <>
      <section
        className={clsx(styles.mainHeader, {
          [styles.commonWrapper]: isCommonWrapper,
        })}
      >
        {!isCommonWrapper && (
          <>
            <div className={styles.container}>
              <div
                className={styles.background}
                style={{ backgroundImage: `url(${banner1})` }}
              />
              <div className="container">
                <div className={styles.content}>
                  <h1>MAXIMIZE O RESULTADO DO SEU NEGÓCIO</h1>
                  <p>
                    Consultoria Especializada em Gestão. Ajudamos empresas
                    fornecendo soluções para seus desafios de forma ágil,
                    prática e inovadora, por meio de boas práticas de mercado em
                    Processos, Qualidade, Compliance e Tecnologia.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.floatIcon}
              style={{ backgroundImage: `url(${iconMain})` }}
            />
          </>
        )}
        {isCommonWrapper && (
          <>
            <div className={clsx(styles.container)}>
              {pageTitle && (
                <div className={clsx(styles.mainTitle, 'container')}>
                  <SectionTitle
                    title={pageTitle}
                    whiteFavicon={true}
                    className="no-padding"
                  />
                </div>
              )}
            </div>
          </>
        )}
      </section>
    </>
  )
}
