import React from 'react'
import '../../styles/global.scss'
import clsx from 'clsx'

import * as styles from '../../styles/pages/privacyPolicy.module.scss'

import { SEO } from '../../components/SEO'
import { Header } from '../../components/Header'
import { ToastAlert } from '../../components/ToastAlert'
import { MegaFooter } from '../../components/MegaFooter'
import { HeaderWrapper } from '../../components/HeaderWrapper'

const lastUpdate = `24 de Março de 2021`

const PrivacyPolicy: React.FC = () => {
  return (
    <main>
      <SEO title="Melhoria de Projetos - Valore Brasil" />
      <ToastAlert />
      <Header />
      <HeaderWrapper
        isCommonWrapper={true}
        pageTitle="Política de Privacidade"
      />
      <section className={styles.policy}>
        <div className="container">
          <div className="row">
            <div className="col-12 pb-4">
              <i>Ultima Atualizaçāo em {lastUpdate}</i>
            </div>
            <div className="col-12">
              <p>
                <strong>Objetivo</strong>
              </p>

              <p>
                A finalidade desta política de privacidade é explicar as
                práticas da <span>“Valore Melhoria”</span>, fazendo negócios
                como Valore (“<span>Valore</span>”, “<span>nós</span>”, “
                <span>nos</span>”, “<span>nosso(a)</span>” ou “
                <span>conosco</span>”) durante o uso e tratamento de dados.
                <br />
              </p>

              <p>
                Quando você visita o nosso site,{' '}
                <a href="https://valoremelhoria.com.br/" target="_self">
                  https://valoremelhoria.com.br
                </a>{' '}
                e de forma mais geral, usar qualquer um dos nossos serviços, que
                incluem o site, agradecemos o fato de você estar nos cofiando
                suas informações pessoais. A sua privacidade é muito importante
                para nós, por isso reunimos todas as informações que você
                precisa saber como os seus dados são processados e tratados. Se
                houver quaisquer termos neste aviso de privacidade com os quais
                você não concorda, interrompa o uso dos nossos serviços
                imediatamente.
              </p>
              <p>
                <strong>
                  Leia este aviso de privacidade com atenção, pois ele o ajudará
                  a entender o que fazemos com as informações que coletamos.
                </strong>
              </p>

              <p>
                <strong>O que são dados pessoais?</strong>
              </p>
              <p>
                Dados pessoais são quaisquer informações relacionadas à pessoa
                natural, identificada ou identificável, ou seja, são dados que
                permitem identificar uma pessoa, como, nome, data de nascimento,
                RG, dados de localização entre outros.
                <br />
                Não são considerados dados pessoais aqueles que foram
                anonimizados, uma vez que após a realização deste processo, não
                é capaz de identificar tal informação.
              </p>

              <p>
                <strong>Quais informações são coletadas?</strong>
              </p>
              <p>
                <strong>Informações pessoais que você divulga para nós:</strong>
              </p>
              <p>
                <span>Resumindo:</span>
                <i> coletamos informações pessoais que você nos fornece.</i>
              </p>
              <p>
                Coletamos informações pessoais que você voluntariamente nos
                fornece quando manifesta interesse em obter informações sobre
                nós ou nossos produtos e serviços ou de outra forma quando você
                nos contatar.
              </p>
              <p>
                As informações pessoais que coletamos dependem do contexto de
                suas interações conosco e com o nosso site. As informações que
                coletamos podem incluir o seguinte:
              </p>
              <p>
                <span>Informações pessoais fornecidas por você: </span>
                nome completo; números de telefone; endereço de e-mail; e outras
                informações semelhantes.
              </p>
              <p>
                <strong>Informações coletadas automaticamente:</strong>
              </p>
              <p>
                <span>Resumindo:</span>
                <i>
                  algumas informações – como o seu endereço de protocolo de
                  internet (IP) e/ou navegador e características do dispositivo
                  – são coletadas automaticamente quando você visita nosso site
                  na rede internet.{' '}
                </i>
              </p>
              <p>
                Coletamos automaticamente certas informações quando você visita,
                usa ou navega em nosso site. São inseridos ‘cookies’ no seu
                navegador e coletados, anonimamente, informações como endereço
                IP, localização geográfica, duração das visitas e páginas
                visitadas. Você pode consentir ou não com uso destes cookies,
                para isso consulte o aviso
                <span> “Nós usamos cookies”. </span>
              </p>
              <p>
                <span>Informações de menores:</span>
                <br />
                <span>Resumindo:</span>
                <i>
                  não coletamos dados de forma intencional ou comercializamos
                  para crianças menores de 18 anos.
                </i>
              </p>
              <p>
                Não solicitamos dados ou comercializamos intencionalmente para
                crianças menores de 18 anos. Usando nosso site, você declara que
                tem pelo menos 18 anos ou que é o pai ou responsável por tal
                menor e consente com o uso do site.
              </p>
              <p>
                Se soubermos que informações pessoais de usuários menores de 18
                anos foram coletadas, desativaremos a conta e tomaremos as
                medidas cabíveis para excluir imediatamente tais dados de nossos
                registros. Se você souber de quaisquer dados que possamos ter
                coletado de crianças menores de 18 anos, entre em contato
                conosco em <span> dpo@valorebrasil.com.br</span>
              </p>
              <p>
                <strong>O que é feito com as informações coletadas?</strong>
                <br />
                <span>Resumindo: </span>
                <i>
                  Processamos suas informações para fins com base em interesses
                  comerciais legítimos, no cumprimento de nosso contrato com
                  você, no cumprimento de nossas obrigações legais e/ou em seu
                  consentimento.
                </i>
              </p>
              <p>
                Usamos informações pessoais coletadas por meio de nosso site na
                rede internet para uma variedade de fins comerciais descritos
                abaixo. Processamos suas informações pessoais para esses fins
                com base em nossos interesses comerciais legítimos, a fim de
                celebrar ou executar um contrato com você, com o seu
                consentimento e/ou para o cumprimento de nossas obrigações
                legais. Indicamos os fundamentos de processamento específicos
                nos quais confiamos ao lado de cada propósito listado abaixo:
              </p>
              <p>Usamos as informações que coletamos ou recebemos:</p>
              <ul>
                <li>
                  <span>Para enviar informações para você: </span> Podemos usar
                  suas informações para enviar informações sobre produtos,
                  serviços e novos recursos e/ou informações em nossos termos,
                  condições e políticas.
                </li>
                <li>
                  <span>Para proteger nossos serviços: </span> Podemos usar suas
                  informações como parte de nossos esforços para manter nosso
                  site seguro e protegido (ex. monitoramento e prevenção de
                  fraude).
                </li>
                <li>
                  <span>
                    Para responder às solicitações legais e prevenir danos:{' '}
                  </span>{' '}
                  se recebermos uma intimação ou outra solicitação legal,
                  podemos precisar inspecionar os dados que possuímos para
                  determinar como responder.
                </li>
                <li>
                  <span>
                    Para entregar e facilitar a entrega de serviços ao usuário:{' '}
                  </span>{' '}
                  Podemos usar suas informações para fornecer o serviço
                  solicitado.
                </li>
                <li>
                  <span>Para enviar comunicações de marketing:</span> Nós e/ou
                  nossos parceiros de marketing terceirizados podemos usar as
                  informações pessoais que você nos envia para nossos fins de
                  marketing, se isso estiver de acordo com suas preferências de
                  marketing. Por exemplo, ao expressar interesse em obter
                  informações sobre nós ou nosso site, inscrevendo-se em
                  marketing ou outra forma nos contatando, coletaremos suas
                  informações pessoais. Você pode cancelar o recebimento de
                  nossos e-mails de marketing a qualquer momento (consulte{' '}
                  <span> “Direitos de privacidade”</span> abaixo).
                </li>
              </ul>
              <p>
                <strong>Com quem os dados são compartilhados?</strong>
                <span>Resumindo:</span>
                <i>
                  Nós apenas compartilhamos informações com o seu consentimento,
                  para cumprir as leis, para lhe fornecer serviços, para
                  proteger seus direitos ou para cumprir obrigações comerciais.
                </i>
              </p>
              <p>
                Podemos processar ou compartilhar seus dados que mantemos com
                base na seguinte base legal:
              </p>
              <ul>
                <li>
                  <span>Consentimento: </span> Podemos processar seus dados se
                  você nos der consentimento especifico para usar suas
                  informações pessoais para uma finalidade especifica.
                </li>
                <li>
                  <span>Interesses Legítimos: </span> Podemos processar seus
                  dados quando for razoavelmente necessário para atingir nossos
                  interesses comerciais legítimos.
                </li>
                <li>
                  <span>Execução de um contrato: </span> Quando celebramos um
                  contrato com você, podemos processar suas informações pessoais
                  para cumprir os termos do nosso contrato.
                </li>
                <li>
                  <span>Obrigações legais: </span> Podemos divulgar suas
                  informações quando formos legalmente obrigados a fazê-lo, a
                  fim de cumprir a lei aplicável, solicitações governamentais,
                  um processo judicial, ordem judicial ou processo legal, como
                  em resposta a uma ordem judicial ou uma intimação (inclusive
                  em resposta às autoridades públicas para atender aos
                  requisitos de segurança nacional ou aplicações da lei).
                </li>
                <li>
                  <span>Interesses vitais: </span> Podemos divulgar suas
                  informações onde acreditamos ser necessário investigar,
                  prevenir ou tomar medidas em relação a possíveis violações de
                  nossas políticas, suspeita de fraude, situações que envolvam
                  ameaças potenciais à segurança de qualquer pessoa e atividades
                  ilegais, ou como evidência em litígios em que estamos
                  envolvidos.
                </li>
              </ul>
              <p>
                Mais especificamente, podemos precisar processar seus dados ou
                compartilhar suas informações pessoais nas seguintes situações:
              </p>
              <ul>
                <li>
                  <span>Transferência de negócios: </span> Podemos compartilhar
                  ou transferir suas informações em conexão com, ou durante as
                  negociações de, qualquer fusão, venda de ativos da empresa,
                  financiamento ou aquisição de todo ou parte de nossos negócios
                  para outra empresa.
                </li>
                <li>
                  <span>
                    Fornecedores, consultores e outros provedores de serviços
                    terceirizados:{' '}
                  </span>
                  Podemos compartilhar seus dados com fornecedores
                  terceirizados, provedores de serviços ou agentes que prestam
                  serviços para nós ou em nosso nome e requerem acesso a tais
                  informações para fazer esses trabalhos. Os exemplos incluem:
                  processamento de pagamentos, análise de dados, entrega de
                  e-mail, serviços de hospedagem, atendimento ao cliente e
                  esforços de marketing. Podemos permitir que terceiros
                  selecionados usem tecnologia de rastreamento no site, o que
                  lhes permitirá coletar dados em nosso nome sobre como você
                  interage em nosso site. A menos que descrito neste aviso, não
                  compartilhamos, vendemos, alugamos ou trocamos nenhuma de suas
                  informações com terceiros para fins promocionais.
                </li>
                <li>
                  <span>Afiliados: </span> Podemos compartilhar suas informações
                  com nossos afiliados; nesse caso, exigiremos que esses
                  afiliados honrem este aviso de privacidade. Afiliados incluem
                  nossa empresa matriz e quaisquer subsidiárias ou empresas que
                  controlamos ou que são sob controle comum conosco.{' '}
                </li>
                <li>
                  <span>Parceiros de negócios: </span> Podemos compartilhar suas
                  informações com nossos parceiros de negócio para lhe oferecer
                  determinados produtos, serviços ou promoções.
                </li>
              </ul>
              <p>
                <strong>Com quem serão compartilhadas suas informações:</strong>
              </p>
              <p>
                <span>Resumindo:</span>
                Nós apenas compartilhamos informações com os seguintes
                terceiros.
              </p>
              <p>
                Nós apenas compartilhamos e divulgamos suas informações com os
                seguintes terceiros. Classificamos cada parte para que você
                possa entender facilmente o propósito de nossas práticas de
                coleta e processamento de dados. Se processamos seus dados com
                base em seu consentimento e você deseja revogar seu
                consentimento, entre em contato conosco usando os dados de
                contato fornecidos na seção abaixo{' '}
                <span>“Como obter mais informações?”</span>
              </p>
              <ul>
                <li className={styles.noDisc}>
                  <span>Publicidade, marketing direto e geração de leads:</span>
                </li>
                <li> Google AdSense</li>
                <li className={styles.noDisc}>
                  <span> Serviços de computação em nuvem: </span>
                </li>
                <li> Bitrix24</li>
                <li className={styles.noDisc}>
                  <span> Otimização de conteúdo:</span>
                </li>
                <li> Vídeo do YouTube incorporado</li>
                <li className={styles.noDisc}>
                  <span> Web and mobile analytics:</span>
                </li>
                <li> Google Analytics</li>
              </ul>
              <p>
                <strong>Quais cookies são utilizados?</strong>
              </p>
              <p>
                <span>Os cookies </span> são pequenos arquivos de texto enviados
                pelos sites ao seu navegador e são armazenados no diretório do
                navegador do seu computador. Os cookies são criados quando você
                utiliza o seu navegador para visitar um site que usa cookies
                para acompanhar seus movimentos no site, ajudar você a retomar
                de onde parou, lembrar o login registrado, a seleção de temas,
                as preferências e outras funções de personalização.
              </p>
              <p>
                Você pode concordar em usar cookies quando visitar este site
                pela primeira vez. Você pode, a qualquer momento, alterar ou
                retirar seu consentimento da Declaração de Cookies em nosso
                site, clicando no ícone "Cookie" na parte inferior do site. Se
                você não concordar em usar cookies ou retirar seu consentimento
                posteriormente, algumas das funções e/ou conteúdo do site podem
                ser limitados.
              </p>
              <p>
                <strong>Preferências de cookies</strong>
              </p>
              <p>
                Abaixo, você pode encontrar informações sobre os propósitos
                pelos quais nós e nossos parceiros usamos cookies e processamos
                dados. Você pode exercitar suas preferências de processamento
                e/ou ver detalhes nos sites dos nossos parceiros.
              </p>
              <p>
                <strong>Cookies analíticos:</strong>
              </p>
              <p>
                <i>Google Analytics</i>
              </p>
              <p>
                O Google Analytics nos permite coletar e analisar informações
                sobre sua experiência de navegação. Esta informação não
                identifica você.
              </p>
              <p>
                <a
                  href="https://policies.google.com/technologies/cookies#types-of-cookies"
                  target="_self"
                >
                  Saiba mais sobre os arquivos usados pelo Google
                </a>
                <br />
                <a
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                  target="_self"
                >
                  Saiba mais sobre cookies e políticas de privacidade do Google
                  Analytics
                </a>
              </p>
              <p>
                <i>Google Tag Manager</i>
              </p>
              <p>
                O Google Tag Manager é um sistema de gerenciamento de tags.
                Usamos tags para rastrear e analisar os caminhos de navegação
                dos visitantes em nosso site. As informações coletadas com o
                Google Tag Manager não podem identificar você; existe para
                tornar nosso site mais acessível.
                <br />
                <a
                  href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/"
                  target="_self"
                >
                  Saiba mais sobre os termos de uso do Google Tag Manager
                </a>
              </p>
              <p>
                <i>Facebook pixel</i>
              </p>
              <p>
                Este site usa pixel do Facebook, que utiliza cookies para
                personalizar anúncios e conteúdo, fornece recursos de mídia
                social e analisar nosso tráfego. Também compartilhamos
                informações sobre o seu uso do nosso site com parceiros
                confiáveis de mídia social, publicidade e análise.
                <br />
                <a
                  href="https://www.facebook.com/policies/cookies/"
                  target="_self"
                >
                  Saiba mais sobre a política de cookies do Facebook
                </a>
              </p>
              <p>
                <strong>Cookies Funcionais:</strong>
              </p>
              <p>
                <i>Youtube</i>
              </p>
              <p>
                O YouTube usa cookies para obter configurações do vídeo
                incorporado quando ele está sendo reproduzido.
                <br />
                <a href="https://www.youtube.com/t/terms" target="_self">
                  Saiba mais sobre as políticas do YouTube
                </a>
              </p>
              <p>
                <a
                  href="https://policies.google.com/technologies/cookies#types-of-cookies"
                  target="_self"
                >
                  Arquivos usados pelo Google
                </a>
              </p>
              <p>
                <i>Google Maps</i>
                <br />
                Nosso site usa recursos e conteúdo fornecidos pelo Google Maps
                nos{' '}
                <a
                  href="https://maps.google.com/help/terms_maps/"
                  target="_self"
                >
                  Termos de Serviço Adicionais do Google Maps/Google Earth e
                  Política de Privacidade do Google
                </a>
              </p>
              <p>
                <strong>Por quanto tempo as informações são mantidas?</strong>
              </p>
              <p>
                <span>Resumindo: </span>
                <i>
                  Nosso objetivo é proteger suas informações pessoais por meio
                  de um sistema de medidas de segurança organizacional e
                  técnica.
                </i>
                <br />
                Implementamos medidas de segurança técnicas e organizacionais
                apropriadas destinadas a proteger a segurança de qualquer
                informação pessoal que processamos. Os seus dados são acessados
                somente por profissionais devidamente autorizados, respeitando
                os princípios de proporcionalidade, necessidade e relevância
                para os nossos objetivos, além do compromisso de
                confidencialidade e preservação da privacidade.
              </p>
              <p>
                No entanto, apesar de nossas salvaguardas e esforços para
                proteger suas informações, nenhuma transmissão eletrônica pela
                internet ou tecnologia de armazenamento de informações pode ser
                garantida como 100% segura, portanto, não podemos prometer ou
                garantir que hackers, cibercriminosos ou outros terceiros não
                autorizados não serão capazes de violar nossa segurança e
                coletar, acessar, roubar, modificar indevidamente suas
                informações. Embora façamos o nosso melhor para proteger suas
                informações pessoais, a transmissão de informações pessoais é
                por sua própria conta e risco.
              </p>
              <p>
                <strong>Quais são os direitos de privacidade?</strong>
              </p>
              <p>
                <span>Resumindo: </span>
                <i>
                  De acordo com a{' '}
                  <span>
                    {' '}
                    Lei Geral de Proteção de Dados (“LGPD”) do Brasil
                  </span>
                  , você tem o direito de acessar, corrigir, portar, eliminar
                  seus dados, além de confirmar que tratamos seus dados.
                </i>
              </p>
              <p>
                Você pode solicitar ao nosso encarregado de dados pessoais (DPO)
                a confirmação da existência do tratamento de dados pessoais e em
                determinadas circunstâncias, você também tem o direito de se
                opor e restringir o tratamento de seus dados pessoais.
              </p>
              <p>
                Você também tem o direito de enviar um requerimento à Autoridade
                Nacional de Proteção de Dados (“ANPD”) entrando em contato
                diretamente com a{' '}
                <a
                  href="https://www.gov.br/anpd/pt-br/assuntos/incidente-de-seguranca"
                  target="_self"
                >
                  ANPD
                </a>
                .
              </p>
              <p>
                Para solicitar a revisão, atualização ou exclusão de suas
                informações pessoais, por favor consultar “Como obter mais
                informações”.
              </p>
              <p>
                <strong>Atualizações em nossa política</strong>
              </p>
              <p>
                <span>Resumindo:</span>
                <i>
                  Sim, atualizaremos este aviso conforme necessário para ficar
                  em conformidade com as leis relevantes.
                </i>
              </p>
              <p>
                Podemos atualizar este aviso de privacidade de tempos em tempos.
                A versão atualizada será indicada por uma data “Revisada”
                atualizada e a versão atualizada entrará em vigor assim que
                estiver acessível. Se fizermos alterações significativas a este
                aviso de privacidade, podemos notificá-lo publicando em destaque
                um aviso de tais alterações ou enviando-lhe diretamente uma
                notificação. Nós o encorajamos a revisar este aviso de
                privacidade frequentemente para ser informado sobre como estamos
                protegendo suas informações.
              </p>
              <p>
                <strong>Como obter mais informações?</strong>
              </p>
              <p>
                <strong>Quem é o controlador dos meus dados?</strong>
                <br />O controlador é aquele a quem compete as decisões sobre o
                tratamento de dados pessoais.
              </p>
              <p>
                <strong>Informações de contato do controlador:</strong>
              </p>
              <p>
                A <span>“Valore Melhoria de Resultados”</span> é o controlador
                de dados responsável por suas informações.
              </p>
              <p>
                E-mail:{' '}
                <a href="mailto:melhoria@valorebrasil.com.br" target="_self">
                  melhoria@valorebrasil.com.br
                </a>
                <br />
                Telefone: +55 16 3441-6888 | 16 99398-2007
              </p>
              <p>
                Endereço:{' '}
                <a
                  href="https://www.google.com.br/maps/place/Edif%C3%ADcio+Times+Square+Business/@-21.2061969,-47.8129622,17z/data=!3m1!4b1!4m5!3m4!1s0x94b9bed24dbc5bcd:0x372dfd4e964cd0f4!8m2!3d-21.2061969!4d-47.8107735"
                  target="_self"
                >
                  Avenida Presidente Vargas, 2121 – Salas 908 e 909 – Ed Times
                  Square | Ribeirão Preto/SP
                </a>
              </p>
              <p>
                <strong>Quem é o encarregado de dados?</strong>
              </p>
              <p>
                Se você tiver perguntas ou dúvidas sobre como utilizamos os seus
                dados, sobre esta política de privacidade ou referente ao uso de
                cookies ou outras tecnologias, não hesite em nos contatar
                através do nosso encarregado de dados.
              </p>
              <p>
                <strong>
                  Informações de contato do encarregado de dados (DPO):
                </strong>
                <br />
                E-mail:{' '}
                <a href="mailto:dpo@valorebrasil.com.br" target="_self">
                  dpo@valorebrasil.com.br
                </a>
                <br />
                Telefone: +55 11 99565-2924
              </p>
            </div>
          </div>
        </div>
      </section>

      <MegaFooter isSimpleFooter={true} />
    </main>
  )
}

export default PrivacyPolicy
